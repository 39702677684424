@use '/src/css/variables' as *
@use '/src/css/graphs/base' as base

.tooltip :global
    z-index: 9999
    position: absolute
    transform: translate(-50%, -50%)
    margin-top: -2em
    left: 50%
    width: max-content
    cursor: default

    div
        background: $satys-dark-blue
        border-radius: 5px
        color: #ffffff
        padding: 1rem 1.5rem
        max-width: 250px
        text-align: center
        animation: $transition opacity
        @include base.triangle(bottom, 5px, $satys-dark-blue)

        &.invisible
            opacity: 0
            display: none

        &.visible
            opacity: 1
            display: block
