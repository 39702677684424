:root {
  --light-grey: #eee;
  --satys-dark-accent: #3f040c;
  --satys-accent: #ba2e6d;
  --satys-light-accent: #fbf8fd;
  --satys-dark-blue: #001b44;
  --satys-purple: #833ab4;
  --satys-red: #fd1d1d;
  --satys-light-purple: #c980e1;
  --satys-light-red: #eb6e6e;
  --satys-light-blue: #add1eb;
  --satys-light-green: #33d9b2;
  --satys-graph-red: #ae7f7f;
  --satys-graph-green: #84ad7f;
  --satys-graph-blue: #93d9f1;
  --satys-graph-neutral: #dedede;
  --satys-text-color: #1b1249;
  --satys-text-color-light: #8b97a9;
  --satys-bg-color: #f9f9fb;
  --header-height: 0px;
  --animation-duration: 500;
  --animation-duration-long: 1000;
}

.titleseparator {
  padding: 2rem 0;
}

.titleseparator .box i.title-icon {
  color: #ba2e6d;
  font-size: 4rem;
}

.titleseparator .box .title-box {
  vertical-align: top;
  margin-left: 1rem;
}

.titleseparator .box .title-box .title {
  margin: 0 0 .5rem;
  font-size: 2rem;
}

:root {
  --light-grey: #eee;
  --satys-dark-accent: #3f040c;
  --satys-accent: #ba2e6d;
  --satys-light-accent: #fbf8fd;
  --satys-dark-blue: #001b44;
  --satys-purple: #833ab4;
  --satys-red: #fd1d1d;
  --satys-light-purple: #c980e1;
  --satys-light-red: #eb6e6e;
  --satys-light-blue: #add1eb;
  --satys-light-green: #33d9b2;
  --satys-graph-red: #ae7f7f;
  --satys-graph-green: #84ad7f;
  --satys-graph-blue: #93d9f1;
  --satys-graph-neutral: #dedede;
  --satys-text-color: #1b1249;
  --satys-text-color-light: #8b97a9;
  --satys-bg-color: #f9f9fb;
  --header-height: 0px;
  --animation-duration: 500;
  --animation-duration-long: 1000;
}

.tinycards {
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 2rem;
  margin-bottom: 2rem;
  display: grid;
}

.tinycards .tinycard {
  filter: drop-shadow(0 0 .3rem #0000001a);
  will-change: filter;
  background: #fff;
  border-radius: .5rem;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem 1rem;
  display: flex;
  position: relative;
}

.tinycards .tinycard:hover .insight-feedback-wrapper {
  display: block;
}

.tinycards .tinycard .leading {
  color: #aaa;
  font-size: .9rem;
  display: block;
}

.tinycards .tinycard .value {
  padding: 1rem 0 0;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1;
  display: block;
}

.tinycards .tinycard .value--small {
  font-size: 1rem;
  font-weight: 400;
}

.tinycards .tinycard .context {
  min-height: 1rem;
  opacity: 0;
  color: #aaa;
  margin-top: .5rem;
  font-size: .8rem;
  font-weight: 400;
  line-height: 1rem;
  transition: opacity .166s;
  display: block;
}

.tinycards .tinycard .card-info {
  cursor: pointer;
  position: absolute;
  top: 1.25rem;
  right: 1rem;
}

.tinycards .tinycard .skeleton .skel {
  border-radius: 5px;
  margin-top: 1rem;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.tinycards:hover .tinycard .context {
  opacity: 1;
}

@media screen and (max-width: 1025px) {
  .tinycards {
    grid-template-columns: repeat(2, minmax(100px, 1fr));
  }
}

@media (max-width: 30em) {
  .tinycards {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media print {
  .tinycards .tinycard {
    filter: none;
    border: 2px solid #eee;
    gap: 1.5rem !important;
  }

  .tinycards .tinycard .value {
    padding: 0;
    font-size: 2.5rem;
  }

  .tinycards .tinycard .context {
    opacity: 1;
  }
}

:root {
  --light-grey: #eee;
  --satys-dark-accent: #3f040c;
  --satys-accent: #ba2e6d;
  --satys-light-accent: #fbf8fd;
  --satys-dark-blue: #001b44;
  --satys-purple: #833ab4;
  --satys-red: #fd1d1d;
  --satys-light-purple: #c980e1;
  --satys-light-red: #eb6e6e;
  --satys-light-blue: #add1eb;
  --satys-light-green: #33d9b2;
  --satys-graph-red: #ae7f7f;
  --satys-graph-green: #84ad7f;
  --satys-graph-blue: #93d9f1;
  --satys-graph-neutral: #dedede;
  --satys-text-color: #1b1249;
  --satys-text-color-light: #8b97a9;
  --satys-bg-color: #f9f9fb;
  --header-height: 0px;
  --animation-duration: 500;
  --animation-duration-long: 1000;
}

.feature-notice {
  pointer-events: none;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.feature-notice.small {
  font-size: .6rem;
}

.feature-notice.faded {
  background-color: #00000006;
  border-radius: .5rem;
}

.feature-notice.faded ~ * {
  filter: grayscale();
  pointer-events: none;
  user-select: none;
}

.feature-notice > div {
  width: 15em;
  text-align: center;
  color: #fbf8fd;
  padding: .2em;
  font-weight: bold;
  transform: translate(-33%, 100%)rotate(-45deg);
  box-shadow: 0 .125em .25em #0000002d;
}

.feature-notice .notice {
  background-color: #ba2e6d;
}

.feature-notice .notice.new {
  background-color: #006400;
}

.feature-notice .notice.demo {
  background-color: #c980e1;
}

.feature-notice .notice.coming-soon {
  background: #3f040c;
}

.feature-notice .notice.updated {
  background: #833ab4;
}

@media print {
  .feature-notice .updated {
    box-shadow: none;
  }
}

.insight-feedback-wrapper {
  z-index: 4;
  display: none;
  position: absolute;
  top: -16px;
  right: 16px;
}

.insight-feedback-wrapper .insight-feedback {
  background-color: #fff;
  border-radius: 16px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
  box-shadow: 0 1px 2px #3c40434d, 0 2px 6px 2px #3c404326;
}

.insight-feedback-wrapper .insight-feedback button {
  height: 32px;
  width: 32px;
  cursor: pointer;
  background-color: #0000;
  border: none;
  border-radius: 100%;
  outline: none;
  justify-content: center;
  align-items: center;
  padding: 5px;
  display: flex;
}

.insight-feedback-wrapper .insight-feedback button:hover {
  background-color: #00000008;
}

.insight-feedback-wrapper .insight-feedback button svg {
  color: var(--satys-purple);
  stroke-width: 2px;
}

:root {
  --light-grey: #eee;
  --satys-dark-accent: #3f040c;
  --satys-accent: #ba2e6d;
  --satys-light-accent: #fbf8fd;
  --satys-dark-blue: #001b44;
  --satys-purple: #833ab4;
  --satys-red: #fd1d1d;
  --satys-light-purple: #c980e1;
  --satys-light-red: #eb6e6e;
  --satys-light-blue: #add1eb;
  --satys-light-green: #33d9b2;
  --satys-graph-red: #ae7f7f;
  --satys-graph-green: #84ad7f;
  --satys-graph-blue: #93d9f1;
  --satys-graph-neutral: #dedede;
  --satys-text-color: #1b1249;
  --satys-text-color-light: #8b97a9;
  --satys-bg-color: #f9f9fb;
  --header-height: 0px;
  --animation-duration: 500;
  --animation-duration-long: 1000;
}

.performance-indicator-info_b1bc88 .explanation-smileys {
  padding-bottom: 33%;
  position: relative;
  overflow: hidden;
}

.performance-indicator-info_b1bc88 .explanation-smileys span {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.performance-indicator-info_b1bc88 .explanation-smileys span svg {
  width: 100%;
  height: 100%;
}

.performance-indicator-info_b1bc88 .explanation-smileys span.overlay {
  z-index: 1;
}

.performance-indicator-info_b1bc88 .explanation-smileys span.overlay.positive {
  color: #84ad7f;
}

.performance-indicator-info_b1bc88 .explanation-smileys span.overlay.negative {
  color: #ae7f7f;
}

:root {
  --light-grey: #eee;
  --satys-dark-accent: #3f040c;
  --satys-accent: #ba2e6d;
  --satys-light-accent: #fbf8fd;
  --satys-dark-blue: #001b44;
  --satys-purple: #833ab4;
  --satys-red: #fd1d1d;
  --satys-light-purple: #c980e1;
  --satys-light-red: #eb6e6e;
  --satys-light-blue: #add1eb;
  --satys-light-green: #33d9b2;
  --satys-graph-red: #ae7f7f;
  --satys-graph-green: #84ad7f;
  --satys-graph-blue: #93d9f1;
  --satys-graph-neutral: #dedede;
  --satys-text-color: #1b1249;
  --satys-text-color-light: #8b97a9;
  --satys-bg-color: #f9f9fb;
  --header-height: 0px;
  --animation-duration: 500;
  --animation-duration-long: 1000;
}

.graphcontainer_d4937b .invisible_d4937b {
  opacity: 0;
}

.graphcontainer_d4937b .visible_d4937b {
  opacity: 1;
}

.graphcontainer_d4937b .overlays_d4937b, .graphcontainer_d4937b .labels_d4937b, .graphcontainer_d4937b .tooltip_d4937b, .graphcontainer_d4937b .tick_d4937b, .graphcontainer_d4937b .mouse_lines_d4937b {
  transition: opacity .25s ease-in-out, border-color .25s ease-in-out, background .25s ease-in-out, background-color .25s ease-in-out, all;
}

.graphcontainer_d4937b .legend_d4937b {
  cursor: default;
  color: #aaa;
  font-size: small;
  left: 50%;
  transform: translateX(-50%);
}

.graphcontainer_d4937b .tooltip_d4937b {
  color: #fff;
  background: #001b44;
  padding: .25rem .5rem;
  transition: opacity .25s ease-in-out, border-color .25s ease-in-out, background .25s ease-in-out, background-color .25s ease-in-out, all;
}

.graphcontainer_d4937b .tooltip_d4937b.absolute_d4937b {
  position: absolute;
}

svg {
  width: 100%;
  overflow: visible;
}

svg .tick_d4937b, svg path, svg line, svg .mouse_lines_d4937b {
  pointer-events: none;
}

svg .graphgrid_d4937b, svg .axis_d4937b.y_d4937b .tick_d4937b line {
  color: #ccc;
}

svg .axislabels_d4937b .label_d4937b {
  text-anchor: middle;
  fill: #aaa;
  font-size: small;
  transition: opacity .25s ease-in-out, border-color .25s ease-in-out, background .25s ease-in-out, background-color .25s ease-in-out, all;
}

svg .axislabels_d4937b .label_d4937b.y_d4937b {
  transform: rotate(-90deg);
}

svg .axis_d4937b {
  color: #aaa;
}

svg .domain_d4937b {
  stroke: none;
  fill: none;
}

#dashboard_d4937b .form_d4937b.flex_d4937b > div:not(:last-child) {
  margin-right: 2rem;
}

.card_wrapper_d4937b .card {
  background-color: #fff;
  padding: 1rem 2rem;
  position: relative;
  overflow: visible;
}

.card_wrapper_d4937b .card:hover .insight-feedback-wrapper {
  display: block;
}

.card_wrapper_d4937b .card.filter {
  overflow: hidden;
}

.card_wrapper_d4937b .card .card-info, .card_wrapper_d4937b .card .card-filter-icon {
  cursor: pointer;
}

.card_wrapper_d4937b .card .card-options-icon {
  cursor: pointer;
  aspect-ratio: 1 / 1;
  width: 2rem;
  height: 2rem;
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  transition: background-color .1s ease-in-out;
  display: flex;
  position: relative;
}

.card_wrapper_d4937b .card .card-options-icon:hover, .card_wrapper_d4937b .card .card-options-icon.active {
  background-color: var(--satys-light-accent);
}

.card_wrapper_d4937b .card .card-options-icon svg {
  width: 1.5rem;
}

.card_wrapper_d4937b .card .card-options-menu {
  width: max-content;
  min-width: 12rem;
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  transform-origin: 100% 0;
  background-color: #fff;
  border-radius: .5rem;
  transition: transform, opacity .1s ease-out;
  position: absolute;
  top: 100%;
  right: 0;
  overflow: hidden;
  transform: scale(.1);
}

.card_wrapper_d4937b .card .card-options-menu.show {
  opacity: 1;
  pointer-events: all;
  filter: drop-shadow(0 0 .3rem #0000001a);
  will-change: filter;
  transform: scale(1);
}

.card_wrapper_d4937b .card .card-options-menu-item {
  flex-direction: row;
  align-items: center;
  padding: .5rem 1rem;
  display: flex;
}

.card_wrapper_d4937b .card .card-options-menu-item:hover {
  background-color: var(--satys-light-accent);
}

.card_wrapper_d4937b .card .card-options-menu-item svg {
  width: 1.5rem;
  margin-right: .5rem;
}

.card_wrapper_d4937b .card .card-filters-overlay {
  z-index: 1;
  pointer-events: none;
  opacity: 0;
  background: #0000001a;
  border-radius: .5rem;
  transition: opacity .2s ease-in-out;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.card_wrapper_d4937b .card .card-filters-overlay.active {
  opacity: 1;
  pointer-events: all;
}

.card_wrapper_d4937b .card .card-filters {
  width: max-content;
  max-width: 40%;
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  background-color: #fff;
  border-radius: 0 .5rem .5rem 0;
  padding: 1rem;
  transition: translate .2s ease-in-out;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  overflow-y: auto;
  transform: translateX(100%);
  box-shadow: -10px 0 15px -10px #0003;
}

.card_wrapper_d4937b .card .card-filters.active {
  opacity: 1;
  pointer-events: all;
  transition: none;
  transform: translateX(0);
}

.card_wrapper_d4937b .card .card-filters h4 {
  margin-bottom: 0;
}

.card_wrapper_d4937b .card .card-filters ul {
  padding: 0;
  list-style: none;
}

.card_wrapper_d4937b .card .card-filters ul li {
  display: inline-block;
}

.card_wrapper_d4937b .card .card-filters ul li label {
  margin: 0;
  padding: 0 .5rem;
}

.card_wrapper_d4937b .card .card-filters ul li label.inline {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.card_wrapper_d4937b .card .card-filters ul li label input[type="checkbox"]:not(:checked) ~ i {
  color: var(--dark-grey);
}

.card_wrapper_d4937b .card .card-filters-heading {
  justify-content: space-between;
  align-items: center;
  padding: .5rem 0;
  display: flex;
}

.card_wrapper_d4937b .card .card-filters-heading h3 {
  margin: 0;
}

.card_wrapper_d4937b .card .card-filters-heading i {
  cursor: pointer;
}

.card_wrapper_d4937b .card .card-filters .no-user-select {
  -webkit-user-select: none;
}

.card_wrapper_d4937b .card .sentiment-emoji-1 {
  color: #ae7f7f;
  fill: #ae7f7f;
}

.card_wrapper_d4937b .card .sentiment-emoji-2 {
  color: #ad967f;
  fill: #ad967f;
}

.card_wrapper_d4937b .card .sentiment-emoji-3 {
  color: #adad7f;
  fill: #adad7f;
}

.card_wrapper_d4937b .card .sentiment-emoji-4 {
  color: #96ad7f;
  fill: #96ad7f;
}

.card_wrapper_d4937b .card .sentiment-emoji-5 {
  color: #84ad7f;
  fill: #84ad7f;
}

@media print {
  .card_wrapper_d4937b .card {
    margin-bottom: 1rem;
  }
}

.card_info_dialog_d4937b .pe-dialog__content {
  width: 64rem;
  max-width: 80%;
}

.card_info_dialog_d4937b .pe-dialog-pane {
  max-width: 100%;
}

:root {
  --light-grey: #eee;
  --satys-dark-accent: #3f040c;
  --satys-accent: #ba2e6d;
  --satys-light-accent: #fbf8fd;
  --satys-dark-blue: #001b44;
  --satys-purple: #833ab4;
  --satys-red: #fd1d1d;
  --satys-light-purple: #c980e1;
  --satys-light-red: #eb6e6e;
  --satys-light-blue: #add1eb;
  --satys-light-green: #33d9b2;
  --satys-graph-red: #ae7f7f;
  --satys-graph-green: #84ad7f;
  --satys-graph-blue: #93d9f1;
  --satys-graph-neutral: #dedede;
  --satys-text-color: #1b1249;
  --satys-text-color-light: #8b97a9;
  --satys-bg-color: #f9f9fb;
  --header-height: 0px;
  --animation-duration: 500;
  --animation-duration-long: 1000;
}

.graphcontainer .invisible {
  opacity: 0;
}

.graphcontainer .visible {
  opacity: 1;
}

.graphcontainer .overlays, .graphcontainer .labels, .graphcontainer .tooltip, .graphcontainer .tick, .graphcontainer .mouse_lines {
  transition: opacity .25s ease-in-out, border-color .25s ease-in-out, background .25s ease-in-out, background-color .25s ease-in-out, all;
}

.graphcontainer .legend {
  cursor: default;
  color: #aaa;
  font-size: small;
  left: 50%;
  transform: translateX(-50%);
}

.graphcontainer .tooltip {
  color: #fff;
  background: #001b44;
  padding: .25rem .5rem;
  transition: opacity .25s ease-in-out, border-color .25s ease-in-out, background .25s ease-in-out, background-color .25s ease-in-out, all;
}

.graphcontainer .tooltip.absolute {
  position: absolute;
}

svg {
  width: 100%;
  overflow: visible;
}

svg .tick, svg path, svg line, svg .mouse_lines {
  pointer-events: none;
}

svg .graphgrid, svg .axis.y .tick line {
  color: #ccc;
}

svg .axislabels .label {
  text-anchor: middle;
  fill: #aaa;
  font-size: small;
  transition: opacity .25s ease-in-out, border-color .25s ease-in-out, background .25s ease-in-out, background-color .25s ease-in-out, all;
}

svg .axislabels .label.y {
  transform: rotate(-90deg);
}

svg .axis {
  color: #aaa;
}

svg .domain {
  stroke: none;
  fill: none;
}

#dashboard .form.flex > div:not(:last-child) {
  margin-right: 2rem;
}

.cohort-score-graph {
  flex-flow: wrap;
  flex: 1;
  display: flex;
}

.cohort-score-graph .graphcontainer {
  flex: 1;
  display: flex;
}

.cohort-score-graph .tooltip {
  pointer-events: none;
  transform: translate(-50%, calc(-100% - .5rem));
}

.cohort-score-graph .tooltip.x, .cohort-score-graph .tooltip.kpi-label {
  transform: translateX(-50%);
}

.cohort-score-graph .tooltip.x:before, .cohort-score-graph .tooltip.kpi-label:before {
  content: "";
  width: 0;
  height: 0;
  border: 5px solid #0000;
  border-top: none;
  border-bottom-color: #001b44;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.cohort-score-graph .tooltip.y {
  transform: translate(-100%, -50%);
}

.cohort-score-graph .tooltip.y:before {
  content: "";
  width: 0;
  height: 0;
  borer-right: none;
  border: 5px solid #0000;
  border-left-color: #001b44;
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
}

.cohort-score-graph .mouse_lines path {
  stroke: #001b44;
}

.cohort-score-graph .kpi-lines path {
  stroke: #001b44;
  fill: none;
  stroke-linejoin: round;
  strok-linecap: square;
  stroke-width: .125rem;
  stroke-dasharray: 8 8;
}

.cohort-score-graph circle {
  fill: #93d9f1;
  stroke: #0000001a;
  stroke-width: .125rem;
}

.cohort-score-graph g.labels {
  font-size: .9rem;
  font-weight: bold;
}

.cohort-score-graph .domain {
  stroke: currentColor;
}

.cohort-score-graph .cohort-legend {
  flex-flow: wrap;
  flex: 0 auto;
  align-items: flex-start;
  display: flex;
}

.cohort-score-graph .cohort-legend ul.values {
  flex-direction: column;
  padding-left: 0;
  display: flex;
}

.cohort-score-graph .cohort-legend ul.values li {
  min-width: 0;
  cursor: default;
  border-radius: 8px;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: .5rem;
  font-size: .9rem;
  line-height: 1.5rem;
  transition: all .25s ease-in-out;
  display: flex;
}

.cohort-score-graph .cohort-legend ul.values li .index {
  min-width: 1.5rem;
  min-height: 1.5rem;
  aspect-ratio: 1 / 1;
  text-align: center;
  white-space: nowrap;
  box-sizing: border-box;
  background-color: #93d9f1;
  border: 1px solid #1170ff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-right: .5rem;
  padding: 5px;
  font-weight: bold;
  transition: all .25s ease-in-out;
  display: flex;
}

.cohort-score-graph .cohort-legend ul.values li .cohort {
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 1rem;
  overflow: hidden;
}

.cohort-score-graph .cohort-legend ul.values li .score {
  margin-left: auto;
  padding-left: 1rem;
}

.cohort-score-graph .cohort-legend ul.values li.hover {
  background: #e3e3e3;
}

.cohort-score-graph .cohort-legend ul.values li.hover .index {
  border-color: #001b44;
}

.cohort-score-graph .cohort-legend ul.values li.disabled {
  opacity: .5;
  font-style: italic;
}

.cohort-score-graph svg .dots circle.hover {
  stroke: #001b44;
}

@media screen and (max-width: 1025px) {
  .cohort-score-graph {
    flex-direction: column;
  }
}

@media print {
  .cohort-score-graph {
    flex-wrap: nowrap;
  }

  .cohort-score-graph .cohort-legend .values {
    margin: 0;
  }
}

:root {
  --light-grey: #eee;
  --satys-dark-accent: #3f040c;
  --satys-accent: #ba2e6d;
  --satys-light-accent: #fbf8fd;
  --satys-dark-blue: #001b44;
  --satys-purple: #833ab4;
  --satys-red: #fd1d1d;
  --satys-light-purple: #c980e1;
  --satys-light-red: #eb6e6e;
  --satys-light-blue: #add1eb;
  --satys-light-green: #33d9b2;
  --satys-graph-red: #ae7f7f;
  --satys-graph-green: #84ad7f;
  --satys-graph-blue: #93d9f1;
  --satys-graph-neutral: #dedede;
  --satys-text-color: #1b1249;
  --satys-text-color-light: #8b97a9;
  --satys-bg-color: #f9f9fb;
  --header-height: 0px;
  --animation-duration: 500;
  --animation-duration-long: 1000;
}

.graphcontainer_34e267 .invisible_34e267 {
  opacity: 0;
}

.graphcontainer_34e267 .visible_34e267 {
  opacity: 1;
}

.graphcontainer_34e267 .overlays_34e267, .graphcontainer_34e267 .labels_34e267, .graphcontainer_34e267 .tooltip_34e267, .graphcontainer_34e267 .tick_34e267, .graphcontainer_34e267 .mouse_lines_34e267 {
  transition: opacity .25s ease-in-out, border-color .25s ease-in-out, background .25s ease-in-out, background-color .25s ease-in-out, all;
}

.graphcontainer_34e267 .legend_34e267 {
  cursor: default;
  color: #aaa;
  font-size: small;
  left: 50%;
  transform: translateX(-50%);
}

.graphcontainer_34e267 .tooltip_34e267 {
  color: #fff;
  background: #001b44;
  padding: .25rem .5rem;
  transition: opacity .25s ease-in-out, border-color .25s ease-in-out, background .25s ease-in-out, background-color .25s ease-in-out, all;
}

.graphcontainer_34e267 .tooltip_34e267.absolute_34e267 {
  position: absolute;
}

svg {
  width: 100%;
  overflow: visible;
}

svg .tick_34e267, svg path, svg line, svg .mouse_lines_34e267 {
  pointer-events: none;
}

svg .graphgrid_34e267, svg .axis_34e267.y_34e267 .tick_34e267 line {
  color: #ccc;
}

svg .axislabels_34e267 .label_34e267 {
  text-anchor: middle;
  fill: #aaa;
  font-size: small;
  transition: opacity .25s ease-in-out, border-color .25s ease-in-out, background .25s ease-in-out, background-color .25s ease-in-out, all;
}

svg .axislabels_34e267 .label_34e267.y_34e267 {
  transform: rotate(-90deg);
}

svg .axis_34e267 {
  color: #aaa;
}

svg .domain_34e267 {
  stroke: none;
  fill: none;
}

#dashboard_34e267 .form_34e267.flex_34e267 > div:not(:last-child) {
  margin-right: 2rem;
}

.tooltip_34e267 {
  z-index: 9999;
  width: max-content;
  cursor: default;
  margin-top: -2em;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tooltip_34e267 div {
  color: #fff;
  max-width: 250px;
  text-align: center;
  background: #001b44;
  border-radius: 5px;
  padding: 1rem 1.5rem;
  animation: opacity .25s ease-in-out;
}

.tooltip_34e267 div:before {
  content: "";
  width: 0;
  height: 0;
  border: 5px solid #0000;
  border-top-color: #001b44;
  border-bottom: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip_34e267 div.invisible {
  opacity: 0;
  display: none;
}

.tooltip_34e267 div.visible {
  opacity: 1;
  display: block;
}

:root {
  --light-grey: #eee;
  --satys-dark-accent: #3f040c;
  --satys-accent: #ba2e6d;
  --satys-light-accent: #fbf8fd;
  --satys-dark-blue: #001b44;
  --satys-purple: #833ab4;
  --satys-red: #fd1d1d;
  --satys-light-purple: #c980e1;
  --satys-light-red: #eb6e6e;
  --satys-light-blue: #add1eb;
  --satys-light-green: #33d9b2;
  --satys-graph-red: #ae7f7f;
  --satys-graph-green: #84ad7f;
  --satys-graph-blue: #93d9f1;
  --satys-graph-neutral: #dedede;
  --satys-text-color: #1b1249;
  --satys-text-color-light: #8b97a9;
  --satys-bg-color: #f9f9fb;
  --header-height: 0px;
  --animation-duration: 500;
  --animation-duration-long: 1000;
}

.graphcontainer .invisible {
  opacity: 0;
}

.graphcontainer .visible {
  opacity: 1;
}

.graphcontainer .overlays, .graphcontainer .labels, .graphcontainer .tooltip, .graphcontainer .tick, .graphcontainer .mouse_lines {
  transition: opacity .25s ease-in-out, border-color .25s ease-in-out, background .25s ease-in-out, background-color .25s ease-in-out, all;
}

.graphcontainer .legend {
  cursor: default;
  color: #aaa;
  font-size: small;
  left: 50%;
  transform: translateX(-50%);
}

.graphcontainer .tooltip {
  color: #fff;
  background: #001b44;
  padding: .25rem .5rem;
  transition: opacity .25s ease-in-out, border-color .25s ease-in-out, background .25s ease-in-out, background-color .25s ease-in-out, all;
}

.graphcontainer .tooltip.absolute {
  position: absolute;
}

svg {
  width: 100%;
  overflow: visible;
}

svg .tick, svg path, svg line, svg .mouse_lines {
  pointer-events: none;
}

svg .graphgrid, svg .axis.y .tick line {
  color: #ccc;
}

svg .axislabels .label {
  text-anchor: middle;
  fill: #aaa;
  font-size: small;
  transition: opacity .25s ease-in-out, border-color .25s ease-in-out, background .25s ease-in-out, background-color .25s ease-in-out, all;
}

svg .axislabels .label.y {
  transform: rotate(-90deg);
}

svg .axis {
  color: #aaa;
}

svg .domain {
  stroke: none;
  fill: none;
}

#dashboard .form.flex > div:not(:last-child) {
  margin-right: 2rem;
}

.prioritymatrix {
  flex-flow: wrap;
  flex: 1;
  display: flex;
}

.prioritymatrix .graphcontainer {
  flex: 1;
  display: flex;
}

.prioritymatrix .tooltip {
  transform: translate(-50%, calc(-100% - .5rem));
}

.prioritymatrix .tooltip.x {
  transform: translate(-50%);
}

.prioritymatrix .tooltip.x:before {
  content: "";
  width: 0;
  height: 0;
  border: 5px solid #0000;
  border-top: none;
  border-bottom-color: #001b44;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.prioritymatrix .tooltip.y {
  transform: translate(-100%, -50%);
}

.prioritymatrix .tooltip.y:before {
  content: "";
  width: 0;
  height: 0;
  borer-right: none;
  border: 5px solid #0000;
  border-left-color: #001b44;
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
}

.prioritymatrix svg .quadrants text {
  fill: #fff;
}

.prioritymatrix svg .quadrants .topleft {
  fill: #e7c3c3;
}

.prioritymatrix svg .quadrants .topright {
  fill: #d0ddd0;
}

.prioritymatrix svg .quadrants .bottomleft {
  fill: #efe0d1;
}

.prioritymatrix svg .quadrants .bottomright {
  fill: #ddd;
}

.prioritymatrix svg circle {
  fill: #fbf8fd;
  stroke-width: 2px;
}

.prioritymatrix svg .mouse_lines path {
  stroke: #001b44;
}

.prioritymatrix .prioritymatrix-legend {
  flex-flow: wrap;
  flex: 0 auto;
  align-items: flex-start;
  display: flex;
}

.prioritymatrix .prioritymatrix-legend ul.values {
  flex-direction: column;
  padding: 0;
  display: flex;
}

.prioritymatrix .prioritymatrix-legend ul.values li {
  cursor: default;
  min-width: 0;
  border-radius: 8px;
  flex: 1;
  padding: .5rem;
  font-size: .9rem;
  line-height: 1.5rem;
  transition: all .25s ease-in-out;
}

.prioritymatrix .prioritymatrix-legend ul.values li .index {
  width: 1.5rem;
  height: 1.5rem;
  text-align: center;
  background-color: #f9f9f9;
  border: 1px solid #eaeaea;
  border-radius: 100%;
  flex-basis: 1.5rem;
  margin-right: .5rem;
  font-weight: bold;
  line-height: 1.4rem;
  transition: all .25s ease-in-out;
  display: inline-block;
}

.prioritymatrix .prioritymatrix-legend ul.values li .subject {
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 1rem;
  overflow: hidden;
}

.prioritymatrix .prioritymatrix-legend ul.values li .score {
  margin-left: auto;
  padding-left: 1rem;
}

.prioritymatrix .prioritymatrix-legend ul.values li.hover {
  background: #e3e3e3;
}

.prioritymatrix .prioritymatrix-legend ul.values li.hover .index {
  border-color: #001b44;
}

.prioritymatrix .prioritymatrix-legend ul.values li.disabled {
  opacity: .5;
  font-style: italic;
}

.prioritymatrix svg .dots circle.hover {
  stroke: #001b44;
}

@media screen and (max-width: 1025px) {
  .prioritymatrix {
    flex-direction: column;
  }
}

@media print {
  .prioritymatrix {
    flex-wrap: nowrap;
  }

  .prioritymatrix svg .quadrants text {
    font-size: 1.5rem;
  }

  .prioritymatrix .prioritymatrix-legend .values {
    margin: 0;
  }
}

.smart_summary_0f3980 {
  flex-flow: row;
  margin-top: 0;
  padding: 0 20px 20px 32px;
  display: flex;
  position: relative;
  overflow-y: auto;
}

.smart_summary_0f3980 .analysis {
  width: 30%;
  max-width: 24rem;
  border-right: 1px solid #dedede;
  flex-direction: column;
  margin-right: 2rem;
  padding-right: 2rem;
  font-style: italic;
  line-height: 1.5;
  display: flex;
}

.smart_summary_0f3980 .analysis p {
  margin: 0;
}

.smart_summary_0f3980 .analysis .greetings {
  justify-content: flex-end;
  margin-top: 10px;
  display: flex;
}

.smart_summary_0f3980 .entries {
  width: 100%;
  flex-flow: row;
  display: flex;
  overflow-y: auto;
}

.smart_summary_0f3980 .entries .entry {
  min-width: 40%;
  max-width: 24rem;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 2rem;
  display: flex;
}

.smart_summary_0f3980 .entries .entry:not(.entry:last-child) {
  border-right: 1px solid #dedede;
  margin-right: 2rem;
  padding-right: 2rem;
}

.smart_summary_0f3980 .entries .entry .title {
  margin: 0 0 2rem;
  font-size: 1.5rem;
  font-weight: 700;
}

.smart_summary_0f3980 .entries .entry .title__highlight {
  width: 3rem;
  height: 3px;
  background-color: var(--satys-accent);
  margin-top: 2px;
  display: block;
}

.smart_summary_0f3980 .entries .entry .score {
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 1;
}

.smart_summary_0f3980 .entries .entry .scale {
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 100;
}

.smart_summary_0f3980 .entries .entry .score-difference {
  align-items: center;
  margin: 0;
  font-size: 1.2rem;
  font-weight: 300;
  display: flex;
}

.smart_summary_0f3980 .entries .entry .score-difference--increase {
  color: var(--satys-graph-green);
}

.smart_summary_0f3980 .entries .entry .score-difference--decrease {
  color: var(--satys-graph-red);
}

.smart_summary_0f3980 .entries .entry .score-difference--decrease svg {
  transform: rotateX(180deg);
}

.smart_summary_0f3980 .entries .entry .score-difference svg {
  height: 1.4rem;
  width: auto;
  margin-right: 2px;
}

.smart_summary_0f3980 .entries .entry .text {
  margin: 0 0 0 10px;
  font-size: 1.25rem;
  font-weight: 700;
}

.smart_summary_0f3980 .entries .entry .notice {
  margin-top: 2rem;
  font-size: 1rem;
  line-height: 1.25;
  display: flex;
}

@media print {
  .smart_summary_0f3980 .entries .entry .score-difference svg {
    height: 1.4rem !important;
  }
}

.grid_tables_ed6105 .grid-table {
  padding-right: 10px;
  position: relative;
  overflow-y: auto;
}

@media print {
  .grid_tables_ed6105 .grid-table {
    max-height: unset !important;
  }
}

.grid_tables_ed6105 .grid-table:hover .grid-row .answer .date {
  opacity: 1;
}

.grid_tables_ed6105 .grid-table .grid-row {
  width: 100%;
  grid-template-columns: 4ch minmax(auto, 45ch);
  gap: 1rem;
  padding: 1rem 1rem 1rem 0;
  display: grid;
}

.grid_tables_ed6105 .grid-table .grid-row:not(:first-child) {
  border-top: 1px solid var(--satys-light-accent);
}

.grid_tables_ed6105 .grid-table .grid-row:hover:not(.grid-row--header) {
  background-color: var(--satys-light-accent);
}

.grid_tables_ed6105 .grid-table .grid-row--header {
  border-top: none;
  border-bottom: 1px solid var(--dark-grey);
  background-color: #fff;
  position: sticky;
  top: 0;
}

.grid_tables_ed6105 .grid-table .grid-row > div {
  flex-direction: column;
  display: flex;
}

.grid_tables_ed6105 .grid-table .grid-row .answer {
  white-space: break-spaces;
  justify-content: center;
  align-items: flex-start;
  position: relative;
}

.grid_tables_ed6105 .grid-table .grid-row .answer p {
  margin: 0;
  line-height: 1.3;
}

.grid_tables_ed6105 .grid-table .grid-row .sentiment {
  justify-content: center;
  align-items: flex-end;
}

.type-labels_score .card:hover .score, .type-labels_score .card:hover .label__start, .type-labels_score .card:hover .label__end, .type-labels_score .card:hover .label__score-container .label__score, .type-labels_score .card:hover .label__required-value-container .label__required-value {
  opacity: 1;
}

.type-labels_score .label-score-body {
  max-height: calc(350px - 2rem);
  overflow-x: hidden;
  overflow-y: auto;
}

.type-labels_score .label-score-container {
  flex-direction: column;
  margin-bottom: 2.5rem;
  display: flex;
}

.type-labels_score .label-score-container.no-data {
  margin-bottom: 1.5rem;
  padding-right: .5rem;
}

.type-labels_score .label-score-container.no-data .label {
  margin: 0 0 .5rem;
}

.type-labels_score .label {
  z-index: 5;
  margin: 0 0 1rem;
}

.type-labels_score .score {
  opacity: 0;
  color: #aaa;
  margin: 0 0 1rem;
  font-size: .9rem;
}

.type-labels_score .no-data-available {
  color: #aaa;
  font-size: .9rem;
  font-style: italic;
}

@media print {
  .type-labels_score .label-score-container {
    margin-bottom: 3rem;
  }

  .type-labels_score .label {
    margin: 0 0 1.5rem;
  }

  .type-labels_score .score, .type-labels_score .label__start, .type-labels_score .label__end, .type-labels_score .label__score-container .label__score, .type-labels_score .label__required-value-container .label__required-value {
    opacity: 1 !important;
  }
}

.horizontal-score-bar {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  display: flex;
  position: relative;
}

.horizontal-score-bar .bar {
  height: 10px;
  z-index: 1;
  background: linear-gradient(to right, #ae7f7f, #84ad7f);
  border-radius: 5px;
}

.horizontal-score-bar .bar-hider {
  height: 9px;
  z-index: 2;
  background-color: #fff;
  border-top-right-radius: 5px;
  position: absolute;
  top: -1px;
  right: -1px;
}

.horizontal-score-bar .label__start, .horizontal-score-bar .label__end {
  color: #aaa;
  opacity: 0;
  font-size: .8rem;
  transition: opacity .166s;
  position: absolute;
  bottom: 0;
  transform: translateY(120%);
}

.horizontal-score-bar .label__start {
  left: 0;
}

.horizontal-score-bar .label__end {
  right: 0;
}

.horizontal-score-bar .label__required-value-container {
  width: 0;
  z-index: 3;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateY(55%);
}

.horizontal-score-bar .label__required-value-container .label__required-value {
  color: #aaa;
  opacity: 0;
  font-size: .8rem;
  transition: opacity .166s;
}

.horizontal-score-bar .label__required-value-container .label__required-value-pointer {
  width: 0;
  height: 30px;
  z-index: 0;
  border: 0 dashed #aaa;
  border-right-width: 2px;
}

.horizontal-score-bar .label__score-container {
  width: 0;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-90%);
}

.horizontal-score-bar .label__score-container .label__score {
  color: #505050;
  opacity: 0;
  margin-bottom: 2px;
  font-size: .8rem;
  transition: opacity .166s;
}

:root {
  --light-grey: #eee;
  --satys-dark-accent: #3f040c;
  --satys-accent: #ba2e6d;
  --satys-light-accent: #fbf8fd;
  --satys-dark-blue: #001b44;
  --satys-purple: #833ab4;
  --satys-red: #fd1d1d;
  --satys-light-purple: #c980e1;
  --satys-light-red: #eb6e6e;
  --satys-light-blue: #add1eb;
  --satys-light-green: #33d9b2;
  --satys-graph-red: #ae7f7f;
  --satys-graph-green: #84ad7f;
  --satys-graph-blue: #93d9f1;
  --satys-graph-neutral: #dedede;
  --satys-text-color: #1b1249;
  --satys-text-color-light: #8b97a9;
  --satys-bg-color: #f9f9fb;
  --header-height: 0px;
  --animation-duration: 500;
  --animation-duration-long: 1000;
}

.graphcontainer .invisible {
  opacity: 0;
}

.graphcontainer .visible {
  opacity: 1;
}

.graphcontainer .overlays, .graphcontainer .labels, .graphcontainer .tooltip, .graphcontainer .tick, .graphcontainer .mouse_lines {
  transition: opacity .25s ease-in-out, border-color .25s ease-in-out, background .25s ease-in-out, background-color .25s ease-in-out, all;
}

.graphcontainer .legend {
  cursor: default;
  color: #aaa;
  font-size: small;
  left: 50%;
  transform: translateX(-50%);
}

.graphcontainer .tooltip {
  color: #fff;
  background: #001b44;
  padding: .25rem .5rem;
  transition: opacity .25s ease-in-out, border-color .25s ease-in-out, background .25s ease-in-out, background-color .25s ease-in-out, all;
}

.graphcontainer .tooltip.absolute {
  position: absolute;
}

svg {
  width: 100%;
  overflow: visible;
}

svg .tick, svg path, svg line, svg .mouse_lines {
  pointer-events: none;
}

svg .graphgrid, svg .axis.y .tick line {
  color: #ccc;
}

svg .axislabels .label {
  text-anchor: middle;
  fill: #aaa;
  font-size: small;
  transition: opacity .25s ease-in-out, border-color .25s ease-in-out, background .25s ease-in-out, background-color .25s ease-in-out, all;
}

svg .axislabels .label.y {
  transform: rotate(-90deg);
}

svg .axis {
  color: #aaa;
}

svg .domain {
  stroke: none;
  fill: none;
}

#dashboard .form.flex > div:not(:last-child) {
  margin-right: 2rem;
}

.histogram {
  flex-flow: wrap;
  flex: 1;
  display: flex;
}

.histogram .graphcontainer {
  min-width: 600px;
  flex: 1;
  display: flex;
}

.histogram .kpi path {
  stroke: #001b44;
  stroke-width: 1px;
  stroke-dasharray: 5;
}

.histogram .tooltip {
  transform: translateX(-50%);
}

.histogram .tooltip:before {
  content: "";
  width: 0;
  height: 0;
  border: 5px solid #0000;
  border-top: none;
  border-bottom-color: #001b44;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.histogram .axis.y {
  transform: translateX(-.75rem);
}

.histogram .bars rect {
  transition: filter .1s ease-in-out;
}

.histogram .bars rect.selected {
  filter: drop-shadow(0 0 5px #0003);
}

.histogram .legend {
  min-width: 300px;
  flex-flow: wrap;
  flex: 0 auto;
  align-items: flex-start;
  display: flex;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}

.histogram .legend ul.values {
  width: 100%;
  flex-direction: column;
  margin-top: 0;
  margin-right: 20px;
  padding: 0;
  display: flex;
  overflow-y: auto;
}

.histogram .legend ul.values li {
  cursor: default;
  min-width: 0;
  border-radius: 8px;
  flex: 1;
  align-items: center;
  padding: .5rem;
  font-size: .9rem;
  line-height: 1.5rem;
  transition: all .25s ease-in-out;
  display: flex;
}

.histogram .legend ul.values li .index {
  aspect-ratio: 1;
  text-align: center;
  background-color: #f9f9f9;
  border: 1px solid #eaeaea;
  border-radius: 100%;
  flex-basis: 1.5rem;
  justify-content: center;
  align-items: center;
  margin-right: .5rem;
  padding: .1rem;
  font-weight: bold;
  line-height: 1.4rem;
  transition: all .25s ease-in-out;
  display: flex;
}

.histogram .legend ul.values li .subject {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
  padding-right: 1rem;
  overflow: hidden;
}

.histogram .legend ul.values li .subject:hover {
  white-space: normal;
}

.histogram .legend ul.values li .score {
  margin-left: auto;
  padding-left: 1rem;
}

.histogram .legend ul.values li.hover {
  background: #e3e3e3;
}

.histogram .legend ul.values li.hover .index {
  border-color: #001b44;
}

.histogram .legend ul.values li.disabled {
  opacity: .5;
  font-style: italic;
}

.stacked-comparison-graph svg .text {
  opacity: 0;
  pointer-events: none;
  font-size: .8rem;
}

.stacked-comparison-graph:hover svg .text {
  opacity: 1;
}

.stacked-comparison-graph .title {
  text-align: center;
}

.stacked-comparison-graph .tooltip {
  z-index: 10;
  pointer-events: none;
  white-space: nowrap;
  border-radius: 5px;
  display: block;
  position: absolute;
}

.stacked-comparison-graph #legend .legend-item {
  flex-direction: row;
  align-items: center;
  margin-right: 1rem;
  display: flex;
}

.stacked-comparison-graph #legend .legend-item .legend-color {
  height: 20px;
  width: 20px;
  border-radius: 5px;
  margin-right: 5px;
}

@media print {
  .stacked-comparison-graph svg .text {
    opacity: 1;
  }
}

.stacked_comparison_card_877c9d .select {
  cursor: pointer;
  place-items: center;
  padding: .5rem;
  font-size: 1.1rem;
  font-weight: 500;
  display: flex;
}

:root {
  --light-grey: #eee;
  --satys-dark-accent: #3f040c;
  --satys-accent: #ba2e6d;
  --satys-light-accent: #fbf8fd;
  --satys-dark-blue: #001b44;
  --satys-purple: #833ab4;
  --satys-red: #fd1d1d;
  --satys-light-purple: #c980e1;
  --satys-light-red: #eb6e6e;
  --satys-light-blue: #add1eb;
  --satys-light-green: #33d9b2;
  --satys-graph-red: #ae7f7f;
  --satys-graph-green: #84ad7f;
  --satys-graph-blue: #93d9f1;
  --satys-graph-neutral: #dedede;
  --satys-text-color: #1b1249;
  --satys-text-color-light: #8b97a9;
  --satys-bg-color: #f9f9fb;
  --header-height: 0px;
  --animation-duration: 500;
  --animation-duration-long: 1000;
}

.graphcontainer .invisible {
  opacity: 0;
}

.graphcontainer .visible {
  opacity: 1;
}

.graphcontainer .overlays, .graphcontainer .labels, .graphcontainer .tooltip, .graphcontainer .tick, .graphcontainer .mouse_lines {
  transition: opacity .25s ease-in-out, border-color .25s ease-in-out, background .25s ease-in-out, background-color .25s ease-in-out, all;
}

.graphcontainer .legend {
  cursor: default;
  color: #aaa;
  font-size: small;
  left: 50%;
  transform: translateX(-50%);
}

.graphcontainer .tooltip {
  color: #fff;
  background: #001b44;
  padding: .25rem .5rem;
  transition: opacity .25s ease-in-out, border-color .25s ease-in-out, background .25s ease-in-out, background-color .25s ease-in-out, all;
}

.graphcontainer .tooltip.absolute {
  position: absolute;
}

svg {
  width: 100%;
  overflow: visible;
}

svg .tick, svg path, svg line, svg .mouse_lines {
  pointer-events: none;
}

svg .graphgrid, svg .axis.y .tick line {
  color: #ccc;
}

svg .axislabels .label {
  text-anchor: middle;
  fill: #aaa;
  font-size: small;
  transition: opacity .25s ease-in-out, border-color .25s ease-in-out, background .25s ease-in-out, background-color .25s ease-in-out, all;
}

svg .axislabels .label.y {
  transform: rotate(-90deg);
}

svg .axis {
  color: #aaa;
}

svg .domain {
  stroke: none;
  fill: none;
}

#dashboard .form.flex > div:not(:last-child) {
  margin-right: 2rem;
}

.type-successes_and_opportunities h3 {
  display: flex;
}

.type-successes_and_opportunities h3 i {
  line-height: .9em;
}

.type-successes_and_opportunities h3 div {
  margin-left: 8px;
}

.type-successes_and_opportunities ul {
  padding: 0;
  font-weight: 300;
  list-style: none;
}

.type-successes_and_opportunities ul li a {
  color: #555;
  border-bottom: 1px solid #0003;
  border-left: 2px solid #0000;
  align-items: center;
  display: flex;
}

.type-successes_and_opportunities ul li a:last-child {
  border-bottom: none;
}

.type-successes_and_opportunities ul li a > * {
  margin: 0 4px;
  padding: .5em 0;
}

.type-successes_and_opportunities ul li a > .rank {
  flex: 1;
  margin-left: 0;
}

.type-successes_and_opportunities ul li a > .name {
  flex: 8;
}

.type-successes_and_opportunities ul li a > .score {
  text-align: right;
  white-space: nowrap;
  flex: 1;
}

.type-successes_and_opportunities ul li a.click-through {
  cursor: pointer;
  transition: all .25s ease-in-out;
}

.type-successes_and_opportunities ul li a.click-through:hover {
  color: #fff;
  background-color: #ba2e6d;
}

.type-successes_and_opportunities ul li a .secondary-name {
  opacity: 0;
  margin-right: 1rem;
  transition: all .25s ease-in-out;
}

.type-successes_and_opportunities ul li a:hover .secondary-name {
  opacity: 1;
}

@media print {
  .type-successes_and_opportunities .arrow {
    display: none;
  }

  .type-successes_and_opportunities .arrow li.click-through {
    cursor: pointer;
    transition: border .166s;
  }

  .type-successes_and_opportunities .arrow li.click-through .arrow {
    transition: padding .166s;
  }

  .type-successes_and_opportunities .arrow li.click-through:hover {
    border-left: 2px solid #ba2e6d;
  }

  .type-successes_and_opportunities .arrow li.click-through:hover .arrow {
    padding-left: 5px;
  }
}

:root {
  --light-grey: #eee;
  --satys-dark-accent: #3f040c;
  --satys-accent: #ba2e6d;
  --satys-light-accent: #fbf8fd;
  --satys-dark-blue: #001b44;
  --satys-purple: #833ab4;
  --satys-red: #fd1d1d;
  --satys-light-purple: #c980e1;
  --satys-light-red: #eb6e6e;
  --satys-light-blue: #add1eb;
  --satys-light-green: #33d9b2;
  --satys-graph-red: #ae7f7f;
  --satys-graph-green: #84ad7f;
  --satys-graph-blue: #93d9f1;
  --satys-graph-neutral: #dedede;
  --satys-text-color: #1b1249;
  --satys-text-color-light: #8b97a9;
  --satys-bg-color: #f9f9fb;
  --header-height: 0px;
  --animation-duration: 500;
  --animation-duration-long: 1000;
}

.graphcontainer .invisible {
  opacity: 0;
}

.graphcontainer .visible {
  opacity: 1;
}

.graphcontainer .overlays, .graphcontainer .labels, .graphcontainer .tooltip, .graphcontainer .tick, .graphcontainer .mouse_lines {
  transition: opacity .25s ease-in-out, border-color .25s ease-in-out, background .25s ease-in-out, background-color .25s ease-in-out, all;
}

.graphcontainer .legend {
  cursor: default;
  color: #aaa;
  font-size: small;
  left: 50%;
  transform: translateX(-50%);
}

.graphcontainer .tooltip {
  color: #fff;
  background: #001b44;
  padding: .25rem .5rem;
  transition: opacity .25s ease-in-out, border-color .25s ease-in-out, background .25s ease-in-out, background-color .25s ease-in-out, all;
}

.graphcontainer .tooltip.absolute {
  position: absolute;
}

svg {
  width: 100%;
  overflow: visible;
}

svg .tick, svg path, svg line, svg .mouse_lines {
  pointer-events: none;
}

svg .graphgrid, svg .axis.y .tick line {
  color: #ccc;
}

svg .axislabels .label {
  text-anchor: middle;
  fill: #aaa;
  font-size: small;
  transition: opacity .25s ease-in-out, border-color .25s ease-in-out, background .25s ease-in-out, background-color .25s ease-in-out, all;
}

svg .axislabels .label.y {
  transform: rotate(-90deg);
}

svg .axis {
  color: #aaa;
}

svg .domain {
  stroke: none;
  fill: none;
}

#dashboard .form.flex > div:not(:last-child) {
  margin-right: 2rem;
}

.horizontal_distribution_bar .bars rect {
  stroke: #fff;
  stroke-width: 2px;
}

.horizontal_distribution_bar .bars rect[data-value="1"] {
  fill: #ae7f7f;
}

.horizontal_distribution_bar .bars rect[data-value="2"] {
  fill: #ad967f;
}

.horizontal_distribution_bar .bars rect[data-value="3"] {
  fill: #adad7f;
}

.horizontal_distribution_bar .bars rect[data-value="4"] {
  fill: #96ad7f;
}

.horizontal_distribution_bar .bars rect[data-value="5"] {
  fill: #84ad7f;
}

.horizontal_distribution_bar .type-emoji .overlays text {
  font-family: Material Icons;
  font-size: 1.75rem;
  transform: translateY(14px);
}

.horizontal_distribution_bar .overlays text {
  fill: #fff;
  transform: translateY(5px);
}

.horizontal_distribution_bar .labels text {
  transform: translateY(20px);
}

.pika-single {
  z-index: 9999;
  color: #333;
  background: #fff;
  border: 1px solid #ccc;
  border-bottom-color: #bbb;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  display: block;
  position: relative;
}

.pika-single:before, .pika-single:after {
  content: " ";
  display: table;
}

.pika-single:after {
  clear: both;
}

.pika-single.is-hidden {
  display: none;
}

.pika-single.is-bound {
  position: absolute;
  box-shadow: 0 5px 15px -5px #00000080;
}

.pika-lendar {
  float: left;
  width: 240px;
  margin: 8px;
}

.pika-title {
  text-align: center;
  position: relative;
}

.pika-label {
  z-index: 9999;
  background-color: #fff;
  margin: 0;
  padding: 5px 3px;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.pika-title select {
  cursor: pointer;
  z-index: 9998;
  opacity: 0;
  margin: 0;
  position: absolute;
  top: 5px;
  left: 0;
}

.pika-prev, .pika-next {
  cursor: pointer;
  width: 20px;
  height: 30px;
  text-indent: 20px;
  white-space: nowrap;
  opacity: .5;
  background-color: #0000;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 75% 75%;
  border: 0;
  outline: none;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.pika-prev:hover, .pika-next:hover {
  opacity: 1;
}

.pika-prev, .is-rtl .pika-next {
  float: left;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAYAAAAsEj5rAAAAUklEQVR42u3VMQoAIBADQf8Pgj+OD9hG2CtONJB2ymQkKe0HbwAP0xucDiQWARITIDEBEnMgMQ8S8+AqBIl6kKgHiXqQqAeJepBo/z38J/U0uAHlaBkBl9I4GwAAAABJRU5ErkJggg==");
}

.pika-next, .is-rtl .pika-prev {
  float: right;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAYAAAAsEj5rAAAAU0lEQVR42u3VOwoAMAgE0dwfAnNjU26bYkBCFGwfiL9VVWoO+BJ4Gf3gtsEKKoFBNTCoCAYVwaAiGNQGMUHMkjGbgjk2mIONuXo0nC8XnCf1JXgArVIZAQh5TKYAAAAASUVORK5CYII=");
}

.pika-prev.is-disabled, .pika-next.is-disabled {
  cursor: default;
  opacity: .2;
}

.pika-select {
  display: inline-block;
}

.pika-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 0;
}

.pika-table th, .pika-table td {
  width: 14.2857%;
  padding: 0;
}

.pika-table th {
  color: #999;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  line-height: 25px;
}

.pika-button {
  cursor: pointer;
  box-sizing: border-box;
  width: 100%;
  color: #666;
  text-align: right;
  height: initial;
  background: #f5f5f5;
  border: 0;
  outline: none;
  margin: 0;
  padding: 5px;
  font-size: 12px;
  line-height: 15px;
  display: block;
}

.pika-week {
  color: #999;
  font-size: 11px;
}

.is-today .pika-button {
  color: #3af;
  font-weight: bold;
}

.is-selected .pika-button, .has-event .pika-button {
  color: #fff;
  background: #3af;
  border-radius: 3px;
  font-weight: bold;
  box-shadow: inset 0 1px 3px #178fe5;
}

.has-event .pika-button {
  background: #005da9;
  box-shadow: inset 0 1px 3px #0076c9;
}

.is-disabled .pika-button, .is-inrange .pika-button {
  background: #d5e9f7;
}

.is-startrange .pika-button {
  color: #fff;
  box-shadow: none;
  background: #6cb31d;
  border-radius: 3px;
}

.is-endrange .pika-button {
  color: #fff;
  box-shadow: none;
  background: #3af;
  border-radius: 3px;
}

.is-disabled .pika-button {
  pointer-events: none;
  cursor: default;
  color: #999;
  opacity: .3;
}

.is-outside-current-month .pika-button {
  color: #999;
  opacity: .3;
}

.is-selection-disabled {
  pointer-events: none;
  cursor: default;
}

.pika-button:hover, .pika-row.pick-whole-week:hover .pika-button {
  color: #fff;
  box-shadow: none;
  background: #ff8000;
  border-radius: 3px;
}

.pika-table abbr {
  cursor: help;
  border-bottom: none;
}

:root {
  --light-grey: #eee;
  --satys-dark-accent: #3f040c;
  --satys-accent: #ba2e6d;
  --satys-light-accent: #fbf8fd;
  --satys-dark-blue: #001b44;
  --satys-purple: #833ab4;
  --satys-red: #fd1d1d;
  --satys-light-purple: #c980e1;
  --satys-light-red: #eb6e6e;
  --satys-light-blue: #add1eb;
  --satys-light-green: #33d9b2;
  --satys-graph-red: #ae7f7f;
  --satys-graph-green: #84ad7f;
  --satys-graph-blue: #93d9f1;
  --satys-graph-neutral: #dedede;
  --satys-text-color: #1b1249;
  --satys-text-color-light: #8b97a9;
  --satys-bg-color: #f9f9fb;
  --header-height: 0px;
  --animation-duration: 500;
  --animation-duration-long: 1000;
}

.graphcontainer_8b43cd .invisible_8b43cd {
  opacity: 0;
}

.graphcontainer_8b43cd .visible_8b43cd {
  opacity: 1;
}

.graphcontainer_8b43cd .overlays_8b43cd, .graphcontainer_8b43cd .labels_8b43cd, .graphcontainer_8b43cd .tooltip_8b43cd, .graphcontainer_8b43cd .tick_8b43cd, .graphcontainer_8b43cd .mouse_lines_8b43cd {
  transition: opacity .25s ease-in-out, border-color .25s ease-in-out, background .25s ease-in-out, background-color .25s ease-in-out, all;
}

.graphcontainer_8b43cd .legend_8b43cd {
  cursor: default;
  color: #aaa;
  font-size: small;
  left: 50%;
  transform: translateX(-50%);
}

.graphcontainer_8b43cd .tooltip_8b43cd {
  color: #fff;
  background: #001b44;
  padding: .25rem .5rem;
  transition: opacity .25s ease-in-out, border-color .25s ease-in-out, background .25s ease-in-out, background-color .25s ease-in-out, all;
}

.graphcontainer_8b43cd .tooltip_8b43cd.absolute_8b43cd {
  position: absolute;
}

svg {
  width: 100%;
  overflow: visible;
}

svg .tick_8b43cd, svg path, svg line, svg .mouse_lines_8b43cd {
  pointer-events: none;
}

svg .graphgrid_8b43cd, svg .axis_8b43cd.y_8b43cd .tick_8b43cd line {
  color: #ccc;
}

svg .axislabels_8b43cd .label_8b43cd {
  text-anchor: middle;
  fill: #aaa;
  font-size: small;
  transition: opacity .25s ease-in-out, border-color .25s ease-in-out, background .25s ease-in-out, background-color .25s ease-in-out, all;
}

svg .axislabels_8b43cd .label_8b43cd.y_8b43cd {
  transform: rotate(-90deg);
}

svg .axis_8b43cd {
  color: #aaa;
}

svg .domain_8b43cd {
  stroke: none;
  fill: none;
}

#dashboard_8b43cd .form_8b43cd.flex_8b43cd > div:not(:last-child) {
  margin-right: 2rem;
}

.daterange_8b43cd .pe-button.pe-button--selected {
  pointer-events: all;
  cursor: pointer;
}

.daterange_8b43cd .pe-button.pe-button--selected .pe-button__content {
  background: #ba2e6d33;
}

.daterange_8b43cd .pe-button .pe-button__content {
  padding: 0;
}

.daterange_8b43cd .pe-button .pe-button__content .pe-button__label {
  text-transform: none;
  padding: .5rem;
  font-size: .9rem;
  line-height: 1;
}

.daterange_8b43cd .select {
  display: inline-block;
}

.daterange_8b43cd .select .pe-button__content {
  padding-right: 1rem;
}

.daterange_8b43cd .select .pe-button__content .pe-button__label:after {
  content: "";
  width: 0;
  height: 0;
  border: 6px solid #0000;
  border-bottom: none;
  border-top-color: var(--satys-accent);
  position: absolute;
  top: 50%;
  right: -.5rem;
  transform: translateY(-50%);
}

.daterange_8b43cd .pikaday {
  z-index: 5;
  background: #fff;
  border-radius: .5rem;
  position: absolute;
  right: 0;
  transform: translateY(3rem);
  box-shadow: 2px 2px 4px #0003;
}

.daterange_8b43cd .pikaday:not(.dn) {
  display: flex;
}

.daterange_8b43cd .pikaday .range-presets {
  border-right: 1px solid var(--light-grey);
  flex-direction: column;
  display: flex;
}

.daterange_8b43cd .pikaday .range-presets .pe-button {
  color: #333;
  margin: 0;
}

.daterange_8b43cd .pikaday .calendar .buttons {
  float: right;
}

.daterange_8b43cd .pikaday .calendar .pika-single.side-by-side {
  width: auto;
  color: #404346;
  z-index: 6500;
  background: #fff;
  border: none;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  display: flex;
}

.daterange_8b43cd .pikaday .calendar .pika-single.side-by-side .pika-title {
  border-bottom: 1px solid var(--light-grey);
}

.daterange_8b43cd .pikaday .calendar .pika-single.side-by-side .pika-title .pika-label {
  color: #ba2e6d;
  cursor: pointer;
}

.daterange_8b43cd .pikaday .calendar .pika-single.side-by-side .pika-title .pika-label:hover {
  text-decoration: underline;
}

.daterange_8b43cd .pikaday .calendar .pika-single.side-by-side .pika-title .pika-next, .daterange_8b43cd .pikaday .calendar .pika-single.side-by-side .pika-title .pika-prev {
  font-size: 3rem;
}

.daterange_8b43cd .pikaday .calendar .pika-single.side-by-side .pika-lendar .pika-table abbr {
  cursor: inherit;
  text-decoration: none;
}

.daterange_8b43cd .pikaday .calendar .pika-single.side-by-side .pika-lendar .pika-table .pika-button {
  background: #fff;
}

.daterange_8b43cd .pikaday .calendar .pika-single.side-by-side .pika-lendar .pika-table .pika-button:hover, .daterange_8b43cd .pikaday .calendar .pika-single.side-by-side .pika-lendar .pika-table .is-today .pika-button {
  color: var(--satys-accent);
}

.daterange_8b43cd .pikaday .calendar .pika-single.side-by-side .pika-lendar .pika-table .is-startrange .pika-button, .daterange_8b43cd .pikaday .calendar .pika-single.side-by-side .pika-lendar .pika-table .is-endrange .pika-button {
  color: #fff;
  background: var(--satys-accent);
}

.daterange_8b43cd .pikaday .calendar .pika-single.side-by-side .pika-lendar .pika-table .is-inrange .pika-button {
  color: #000;
  background: #ba2e6d54;
}

.filter_wrapper_145a66 {
  position: relative;
}

.filter_wrapper_145a66 .filter-bar {
  z-index: 1;
  filter: drop-shadow(0 .3rem .15rem #0000000d);
  width: 100%;
  transition: opacity 1s;
}

.filter_wrapper_145a66 .filter-bar--fixed {
  width: calc(100% - 18rem);
  z-index: 2;
  filter: none;
  filter: drop-shadow(0 .3rem .15rem #0000000d);
  border: none;
  border-radius: 0;
  position: fixed;
  top: 3rem;
  right: 0;
}

.filter_wrapper_145a66 .filter-bar .pe-textfield {
  padding-bottom: 0;
}

.filter_wrapper_145a66 .filter-bar .filters {
  flex: 1;
}

.filter_wrapper_145a66 .placeholder {
  width: 100%;
  position: absolute;
  inset: 0;
}

:root {
  --light-grey: #eee;
  --satys-dark-accent: #3f040c;
  --satys-accent: #ba2e6d;
  --satys-light-accent: #fbf8fd;
  --satys-dark-blue: #001b44;
  --satys-purple: #833ab4;
  --satys-red: #fd1d1d;
  --satys-light-purple: #c980e1;
  --satys-light-red: #eb6e6e;
  --satys-light-blue: #add1eb;
  --satys-light-green: #33d9b2;
  --satys-graph-red: #ae7f7f;
  --satys-graph-green: #84ad7f;
  --satys-graph-blue: #93d9f1;
  --satys-graph-neutral: #dedede;
  --satys-text-color: #1b1249;
  --satys-text-color-light: #8b97a9;
  --satys-bg-color: #f9f9fb;
  --header-height: 0px;
  --animation-duration: 500;
  --animation-duration-long: 1000;
}

.analyses-accordion .analyses .analysis {
  flex-basis: 0;
  transform: translateX(0);
}

.analyses-accordion .analyses .analysis.to-right {
  transition: all .25s;
  transform: translateX(100%);
}

.analyses-accordion .analyses .analysis.to-left {
  transition: all .25s;
  transform: translateX(-100%);
}

.analyses-accordion .analyses .analysis .meta {
  color: #0006;
  justify-content: space-between;
  margin: .5rem 0;
  display: flex;
}

.analyses-accordion .analyses .analysis .content {
  font-size: 1rem;
  line-height: 1.5rem;
  overflow-y: auto;
}

.analyses-accordion .navigation .previous, .analyses-accordion .navigation .next {
  top: 50%;
  transform: translateY(-50%);
}

.analyses-accordion .navigation .previous i.material-icons, .analyses-accordion .navigation .next i.material-icons {
  font-size: 2rem;
}

.analyses-accordion .navigation .progress-bar {
  height: .25rem;
}

.analyses-accordion .navigation .progress-bar .progress {
  transition: left .25s;
}

:root {
  --light-grey: #eee;
  --satys-dark-accent: #3f040c;
  --satys-accent: #ba2e6d;
  --satys-light-accent: #fbf8fd;
  --satys-dark-blue: #001b44;
  --satys-purple: #833ab4;
  --satys-red: #fd1d1d;
  --satys-light-purple: #c980e1;
  --satys-light-red: #eb6e6e;
  --satys-light-blue: #add1eb;
  --satys-light-green: #33d9b2;
  --satys-graph-red: #ae7f7f;
  --satys-graph-green: #84ad7f;
  --satys-graph-blue: #93d9f1;
  --satys-graph-neutral: #dedede;
  --satys-text-color: #1b1249;
  --satys-text-color-light: #8b97a9;
  --satys-bg-color: #f9f9fb;
  --header-height: 0px;
  --animation-duration: 500;
  --animation-duration-long: 1000;
}

.graphcontainer_81f1ca .invisible_81f1ca {
  opacity: 0;
}

.graphcontainer_81f1ca .visible_81f1ca {
  opacity: 1;
}

.graphcontainer_81f1ca .overlays_81f1ca, .graphcontainer_81f1ca .labels_81f1ca, .graphcontainer_81f1ca .tooltip_81f1ca, .graphcontainer_81f1ca .tick_81f1ca, .graphcontainer_81f1ca .mouse_lines_81f1ca {
  transition: opacity .25s ease-in-out, border-color .25s ease-in-out, background .25s ease-in-out, background-color .25s ease-in-out, all;
}

.graphcontainer_81f1ca .legend_81f1ca {
  cursor: default;
  color: #aaa;
  font-size: small;
  left: 50%;
  transform: translateX(-50%);
}

.graphcontainer_81f1ca .tooltip_81f1ca {
  color: #fff;
  background: #001b44;
  padding: .25rem .5rem;
  transition: opacity .25s ease-in-out, border-color .25s ease-in-out, background .25s ease-in-out, background-color .25s ease-in-out, all;
}

.graphcontainer_81f1ca .tooltip_81f1ca.absolute_81f1ca {
  position: absolute;
}

svg {
  width: 100%;
  overflow: visible;
}

svg .tick_81f1ca, svg path, svg line, svg .mouse_lines_81f1ca {
  pointer-events: none;
}

svg .graphgrid_81f1ca, svg .axis_81f1ca.y_81f1ca .tick_81f1ca line {
  color: #ccc;
}

svg .axislabels_81f1ca .label_81f1ca {
  text-anchor: middle;
  fill: #aaa;
  font-size: small;
  transition: opacity .25s ease-in-out, border-color .25s ease-in-out, background .25s ease-in-out, background-color .25s ease-in-out, all;
}

svg .axislabels_81f1ca .label_81f1ca.y_81f1ca {
  transform: rotate(-90deg);
}

svg .axis_81f1ca {
  color: #aaa;
}

svg .domain_81f1ca {
  stroke: none;
  fill: none;
}

#dashboard_81f1ca .form_81f1ca.flex_81f1ca > div:not(:last-child) {
  margin-right: 2rem;
}

.timeseries_81f1ca {
  flex-direction: column;
  display: flex;
}

.timeseries_81f1ca .tooltip {
  position: initial;
  pointer-events: none;
  z-index: 1;
  font-size: .75rem;
}

.timeseries_81f1ca .tooltip.kpi {
  transform: translate(100%, -50%);
}

.timeseries_81f1ca .tooltip.kpi:before {
  content: "";
  width: 0;
  height: 0;
  border: 5px solid #0000;
  border-left: none;
  border-right-color: #001b44;
  position: absolute;
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
}

.timeseries_81f1ca .tooltip.x {
  white-space: nowrap;
  transform: translateX(-50%);
}

.timeseries_81f1ca .tooltip.x:before {
  content: "";
  width: 0;
  height: 0;
  border: 5px solid #0000;
  border-top: none;
  border-bottom-color: #001b44;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.timeseries_81f1ca .tooltip.y {
  white-space: nowrap;
  display: block;
  position: absolute;
  top: 0;
  transform: translate(-50%, -100%);
}

.timeseries_81f1ca .tooltip.y:before {
  content: "";
  width: 0;
  height: 0;
  border: 5px solid #0000;
  border-top-color: #001b44;
  border-bottom: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.timeseries_81f1ca .tooltip.y tr > td {
  padding: .25rem;
}

.timeseries_81f1ca .tooltip.y .score {
  text-align: right;
  padding-left: 2rem;
  font-weight: bold;
}

.timeseries_81f1ca .tooltip.y .circle {
  vertical-align: inherit;
  width: 10px;
  height: 10px;
  background-color: #001b44;
  border: 1px solid #fff;
  border-radius: 100%;
  margin-left: -.25rem;
  margin-right: .25rem;
  display: inline-block;
}

.timeseries_81f1ca .tooltip.y .bar-legend {
  width: 10px;
  height: 10px;
  background-color: #888;
  border: 1px solid #fff;
  border-radius: 2px;
  margin-left: -.25rem;
  margin-right: 8px;
  display: inline-block;
}

.timeseries_81f1ca .tooltip.y .line-1 .circle {
  background-color: #888;
}

.timeseries_81f1ca .tooltip.y .line-2 .circle {
  background-color: #d88;
}

.timeseries_81f1ca .tooltip.y .line-3 .circle {
  background-color: #8d8;
}

.timeseries_81f1ca .tooltip.y .line-4 .circle {
  background-color: #88d;
}

.timeseries_81f1ca .tooltip.y .line-5 .circle {
  background-color: #d8d;
}

.timeseries_81f1ca .tooltip.y .line-6 .circle {
  background-color: #dd8;
}

.timeseries_81f1ca .tooltip.y .line-7 .circle {
  background-color: #8dd;
}

.timeseries_81f1ca .tooltip.y [data-key="parent"] .circle {
  background-color: #adb5ff;
}

.timeseries_81f1ca .graph-legend {
  padding: 0 2rem;
}

.timeseries_81f1ca .graph-legend > div {
  margin-bottom: 1rem;
  margin-right: 2rem;
  display: inline-block;
}

.timeseries_81f1ca .graph-legend > div .bar-legend {
  opacity: .2;
  background-color: #888;
}

.timeseries_81f1ca .graph-legend > div:nth-child(2) .line-legend {
  background-color: #888;
}

.timeseries_81f1ca .graph-legend > div:nth-child(3) .line-legend {
  background-color: #d88;
}

.timeseries_81f1ca .graph-legend > div:nth-child(4) .line-legend {
  background-color: #8d8;
}

.timeseries_81f1ca .graph-legend > div:nth-child(5) .line-legend {
  background-color: #88d;
}

.timeseries_81f1ca .graph-legend > div:nth-child(6) .line-legend {
  background-color: #d8d;
}

.timeseries_81f1ca .graph-legend > div:nth-child(7) .line-legend {
  background-color: #dd8;
}

.timeseries_81f1ca .graph-legend > div:nth-child(8) .line-legend {
  background-color: #8dd;
}

.timeseries_81f1ca .graph-legend > div[data-key="parent"] .line-legend {
  background-color: #adb5ff;
}

.timeseries_81f1ca .graph-legend .bar-legend {
  height: 1rem;
  width: 1rem;
  border-radius: 2px;
  margin-right: 8px;
  display: inline-block;
}

.timeseries_81f1ca .graph-legend .line-legend {
  height: .125rem;
  width: 16px;
  border-radius: 8px;
  margin-right: 8px;
  display: inline-block;
  transform: translateY(-125%);
}

.timeseries_81f1ca svg .bars rect {
  fill: #888;
  opacity: .15;
}

.timeseries_81f1ca svg .line {
  stroke-width: 3px;
  fill: none;
  stroke-linejoin: round;
  strok-linecap: square;
}

.timeseries_81f1ca svg .circle {
  pointer-events: none;
  stroke-width: .125rem;
  fill: #fff;
  stroke: #001b44;
}

.timeseries_81f1ca svg .hover {
  z-index: 1;
}

.timeseries_81f1ca svg .hover line {
  stroke-width: 1px;
  stroke: #001b44;
}

.timeseries_81f1ca svg .kpi line {
  stroke-width: 1px;
  stroke: #001b44;
  stroke-dasharray: 5;
}

.timeseries_81f1ca svg .lines .line:nth-child(1) {
  stroke: #888;
}

.timeseries_81f1ca svg .lines .line:nth-child(2) {
  stroke: #d88;
}

.timeseries_81f1ca svg .lines .line:nth-child(3) {
  stroke: #8d8;
}

.timeseries_81f1ca svg .lines .line:nth-child(4) {
  stroke: #88d;
}

.timeseries_81f1ca svg .lines .line:nth-child(5) {
  stroke: #d8d;
}

.timeseries_81f1ca svg .lines .line:nth-child(6) {
  stroke: #dd8;
}

.timeseries_81f1ca svg .lines .line:nth-child(7) {
  stroke: #8dd;
}

.timeseries_81f1ca svg .lines .line[data-key="parent"] {
  stroke: #adb5ff;
  stroke-width: 1px;
}

.timeseries_81f1ca svg .circles circle:nth-child(2), .timeseries_81f1ca svg .circles circle:nth-child(1) {
  fill: #888;
  stroke-width: 1px;
  stroke: #fff;
  pointer-events: none;
}

.timeseries_81f1ca svg .circles circle:nth-child(2).background:not(.invisible), .timeseries_81f1ca svg .circles circle:nth-child(1).background:not(.invisible) {
  stroke: none;
  fill: #888;
  opacity: .5;
}

.timeseries_81f1ca svg .circles circle:nth-child(4), .timeseries_81f1ca svg .circles circle:nth-child(3) {
  fill: #d88;
  stroke-width: 1px;
  stroke: #fff;
  pointer-events: none;
}

.timeseries_81f1ca svg .circles circle:nth-child(4).background:not(.invisible), .timeseries_81f1ca svg .circles circle:nth-child(3).background:not(.invisible) {
  stroke: none;
  fill: #d88;
  opacity: .5;
}

.timeseries_81f1ca svg .circles circle:nth-child(6), .timeseries_81f1ca svg .circles circle:nth-child(5) {
  fill: #8d8;
  stroke-width: 1px;
  stroke: #fff;
  pointer-events: none;
}

.timeseries_81f1ca svg .circles circle:nth-child(6).background:not(.invisible), .timeseries_81f1ca svg .circles circle:nth-child(5).background:not(.invisible) {
  stroke: none;
  fill: #8d8;
  opacity: .5;
}

.timeseries_81f1ca svg .circles circle:nth-child(8), .timeseries_81f1ca svg .circles circle:nth-child(7) {
  fill: #88d;
  stroke-width: 1px;
  stroke: #fff;
  pointer-events: none;
}

.timeseries_81f1ca svg .circles circle:nth-child(8).background:not(.invisible), .timeseries_81f1ca svg .circles circle:nth-child(7).background:not(.invisible) {
  stroke: none;
  fill: #88d;
  opacity: .5;
}

.timeseries_81f1ca svg .circles circle:nth-child(10), .timeseries_81f1ca svg .circles circle:nth-child(9) {
  fill: #d8d;
  stroke-width: 1px;
  stroke: #fff;
  pointer-events: none;
}

.timeseries_81f1ca svg .circles circle:nth-child(10).background:not(.invisible), .timeseries_81f1ca svg .circles circle:nth-child(9).background:not(.invisible) {
  stroke: none;
  fill: #d8d;
  opacity: .5;
}

.timeseries_81f1ca svg .circles circle:nth-child(12), .timeseries_81f1ca svg .circles circle:nth-child(11) {
  fill: #dd8;
  stroke-width: 1px;
  stroke: #fff;
  pointer-events: none;
}

.timeseries_81f1ca svg .circles circle:nth-child(12).background:not(.invisible), .timeseries_81f1ca svg .circles circle:nth-child(11).background:not(.invisible) {
  stroke: none;
  fill: #dd8;
  opacity: .5;
}

.timeseries_81f1ca svg .circles circle:nth-child(14), .timeseries_81f1ca svg .circles circle:nth-child(13) {
  fill: #8dd;
  stroke-width: 1px;
  stroke: #fff;
  pointer-events: none;
}

.timeseries_81f1ca svg .circles circle:nth-child(14).background:not(.invisible), .timeseries_81f1ca svg .circles circle:nth-child(13).background:not(.invisible) {
  stroke: none;
  fill: #8dd;
  opacity: .5;
}

.timeseries_81f1ca svg .circles circle[data-key="parent"] {
  fill: #adb5ff;
}

.timeseries_81f1ca svg .circles circle[data-key="parent"].background:not(.invisible) {
  stroke: none;
  fill: #adb5ff;
  opacity: .5;
}

/*# sourceMappingURL=view_dashboard.bdb290a4.css.map */
