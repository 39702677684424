@use 'src/css/variables' as *
@use 'src/css/graphs/base'

.type-successes_and_opportunities
  h3
    display: flex

    i
      line-height: .9em
    div
      margin-left: 8px

  ul
    padding: 0
    list-style: none
    font-weight: 300

    li a
      display: flex
      border-bottom: solid 1px rgba(0, 0, 0, .2)
      align-items: center
      border-left: 2px solid transparent
      color: #555

      &:last-child
        border-bottom: none

      > *
        margin: 0px 4px
        padding: .5em 0px

      > .rank
        margin-left: 0px
        flex: 1
      > .name
        flex: 8
      > .score
        text-align: right
        flex: 1
        white-space: nowrap

      &.click-through
          cursor: pointer
          transition: $transition
          &:hover
              background-color: $satys-accent
              color: white

      .secondary-name
        opacity: 0
        margin-right: 1rem
        transition: $transition

      &:hover
        .secondary-name
          opacity: 1

@media print
  .type-successes_and_opportunities .arrow
    display: none

    li.click-through
        cursor: pointer
        transition: border 166ms ease

        .arrow
          transition: padding 166ms ease

        &:hover
            border-left: 2px solid $satys-accent

            .arrow
              padding-left: 5px
