@use "src/css/variables" as *
@use "src/css/graphs/base"

.cohort-score-graph
    display: flex
    flex: 1
    flex-direction: row
    flex-wrap: wrap
    
    .graphcontainer
        display: flex
        flex: 1

    .tooltip
        pointer-events: none
        transform: translate(-50%,calc(-100% - 0.5rem))

        &.x, &.kpi-label
            transform: translateX(-50%)
            @include base.triangle(top, 5px, base.$accent)
        &.y
            transform: translate(-100%, -50%)
            @include base.triangle(right, 5px, base.$accent)

    .mouse_lines
        path
          stroke: base.$accent

    .kpi-lines
        path
            stroke: base.$accent
            fill: none
            stroke-linejoin: round
            strok-linecap: square
            stroke-width: base.$default-line-width
            stroke-dasharray: 8, 8
    circle
        fill: $satys-graph-blue
        stroke: rgba(0, 0, 0, .1)
        stroke-width: base.$default-line-width

    g.labels
        font-size: .9rem
        font-weight: bold

    .domain
        stroke: currentColor

    .cohort-legend
        display: flex
        flex: 0 1 auto
        flex-direction: row
        flex-wrap: wrap
        align-items: flex-start

        ul.values
            display: flex
            flex-direction: column
            padding-left: 0

            li
                display: flex
                align-items: center
                justify-content: center
                flex: 1
                min-width: 0
                padding: .5rem
                font-size: .9rem
                line-height: 1.5rem
                border-radius: 8px
                transition: $transition
                cursor: default
                
                .index 
                    display: flex
                    align-items: center
                    justify-content: center
                    min-width: 1.5rem
                    min-height: 1.5rem
                    aspect-ratio: 1 / 1
                    padding: 5px
                    margin-right: .5rem
                    text-align: center
                    white-space: nowrap
                    box-sizing: border-box
                    border: 1px solid lighten(base.$accent, 40)
                    border-radius:  50%
                    font-weight: bold
                    background-color: $satys-graph-blue
                    transition: $transition

                .cohort
                    padding-right: 1rem
                    white-space: nowrap
                    overflow: hidden
                    text-overflow: ellipsis

                .score
                    padding-left: 1rem
                    margin-left: auto

                &.hover
                    background: base.$monochrome_rest_color

                    .index
                        border-color: base.$accent

                &.disabled
                    opacity: 0.5
                    font-style: italic

    svg .dots circle.hover
        stroke: base.$accent

@media screen and (max-width: 1025px)
    .cohort-score-graph
        flex-direction: column

@media print
    .cohort-score-graph
        flex-wrap: nowrap

        .cohort-legend .values
            margin: 0
