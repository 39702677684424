@use "src/css/variables" as *
@use "src/css/graphs/base"
@use 'sass:color'

.daterange :global
    .pe-button
        &.pe-button--selected
            pointer-events: all
            cursor: pointer

            .pe-button__content
                background: color.adjust($satys-accent, $alpha: -0.8)

        .pe-button__content
            padding: 0

            .pe-button__label
                font-size: .9rem
                line-height: 1
                padding: .5rem
                text-transform: none

    .select
        display: inline-block

        .pe-button__content
            padding-right: 1rem

            .pe-button__label:after
                content: ""
                position: absolute
                width: 0
                height: 0

                border-width: 6px
                border-style: solid
                border-color: transparent

                right: -0.5rem
                top: 50%
                transform: translateY(-50%)
                border-bottom: none
                border-top-color: var(--satys-accent)

    .pikaday
        position: absolute
        background: white
        right: 0
        z-index: 5
        border-radius: .5rem
        box-shadow: 2px 2px 4px color.adjust(#000000, $alpha: -0.8)
        transform: translateY(3rem)

        &:not(.dn)
            display: flex

        .range-presets
            display: flex
            flex-direction: column
            border-right: 1px solid var(--light-grey)

            .pe-button
                color: #333
                margin: 0

        .calendar
            .buttons
                float: right

            .pika-single.side-by-side
                width: auto
                display: flex
                justify-content: space-between
                padding: 0
                margin: 0
                border: none

                color: #404346
                background: #fff
                z-index: 6500

                .pika-title
                    border-bottom: 1px solid var(--light-grey)

                    .pika-label
                        color: $satys-accent
                        cursor: pointer

                        &:hover
                            text-decoration: underline

                    .pika-next, .pika-prev
                        font-size: 3rem

                .pika-lendar
                    .pika-table
                        abbr
                            text-decoration: none
                            cursor: inherit
                        .pika-button
                            background: white
                            &:hover
                                color: var(--satys-accent)
                        .is-today .pika-button
                                color: var(--satys-accent)
                        .is-startrange, .is-endrange
                            .pika-button
                                color: white
                                background: var(--satys-accent)
                        .is-inrange
                            .pika-button
                                color: black
                                background: color.adjust($satys-accent, $alpha: -0.67)
