.stacked_comparison_card :global
    .select
        display: flex
        justify-items: center
        align-items: center
        padding: 0.5rem
        font-size: 1.1rem
        font-weight: 500
        cursor: pointer

